import React from 'react'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <>
      <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="section-subtitle">OUR MENU </div> */}
              <div className="section-title">Our Menu</div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <a href="/menu"><img src="/assets/img/food/Ala_Cart.jpeg" alt="" /></a> </div> 
               
                <div className="con">
               
                  <h5><a href="/menu"> A LA CARTE</a> </h5>
                  <div className="line"></div>
                  <div className="row facilities">
                                
                                <div className="col col-md-12 text-end ">
                                    <div className="permalink"><a href="/menu">Details <i className="ti-arrow-right"></i></a></div>
                                </div>
                            </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <a href="/menu"><img src="/assets/img/food/Wine.jpeg" alt="" /></a> </div> 
              
                <div className="con">
                 
                  <h5><a href="/menu">WINE MENU</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">
                                
                                <div className="col col-md-12 text-end ">
                                    <div className="permalink"><a href="/menu">Details <i className="ti-arrow-right"></i></a></div>
                                </div>
                            </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <a href="/menu"><img src="/assets/img/food/Cocktail.jpeg" alt="" /></a> </div> 
               
                <div className="con">
                 
                  <h5><a href="/menu">COCKTAILS MENU</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">
                                
                                <div className="col col-md-12 text-end ">
                                    <div className="permalink"><a href="/menu">Details <i className="ti-arrow-right"></i></a></div>
                                </div>
                            </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> <a href="/menu"><img src="/assets/img/food/Dessert.jpeg" alt="" /></a> </div> 
              
                <div className="con">
                 
                  <h5><a href="/menu">DESSERTS</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">
                                
                                <div className="col col-md-12 text-end ">
                                    <div className="permalink"><a href="/menu">Details <i className="ti-arrow-right"></i></a></div>
                                </div>
                            </div>
                </div>
              </div>
            </div> */}
            
             {/* <div className="col-md-4 col-12">
              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/diwali-special-menu"><img src="/assets/img/food/DiwaliSpecial.jpg" alt="" /></Link> </div>
                <div className="con">

                  <h5><Link to="/menu/diwali-special-menu">DIWALI SPECIAL MENU</Link></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end">
                      <div className="permalink"><Link to="/menu/diwali-special-menu">Details <i className="ti-arrow-right"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-6 col-12">

              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/cart-menu"><img src="/assets/img/food/cart.png" alt="" /></Link></div> 
                {/* <span className="category"><a href="#">Book</a></span> */}
                <div className="con">
                
                  <h5><Link to="/menu/cart-menu">CART MENU</Link></h5>
                  <div className="line"></div>
                  <div className="row facilities">
                                
                                <div className="col col-md-12 text-end">
                                    <div className="permalink"><Link to="/menu/cart-menu">Details <i className="ti-arrow-right"></i></Link></div>
                                </div>
                            </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">

              <div className="item">
                <div className="position-re o-hidden"> <Link to="/menu/restaurant-menu-1"><img src="/assets/img/food/restaurant.png" alt="" /></Link></div> 
                {/* <span className="category"><a href="#">Book</a></span> */}
                <div className="con">
                  
                  <h5><Link to="/menu/restaurant-menu-1">RESTAURANT MENU</Link></h5>
                  <div className="line"></div>
                  <div className="row facilities">
                                
                                <div className="col col-md-12 text-end">
                                    <div className="permalink"><Link to="/menu/restaurant-menu-1">Details <i className="ti-arrow-right"></i></Link></div>
                                </div>
                            </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
    </>
  )
}

export default Menu