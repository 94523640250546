import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import './App.css';
import Contact from './Containers/Contact';
import Menu from './Containers/Menu';
import Pages from './Containers/Pages';
import Career from './Containers/Career';
import Home from './Containers/Home';
import Gallery from './Containers/Gallery';

import Kalagram from './Containers/Kalagram';

import Blogs from './Containers/Blogs';
import News from './Containers/News';
import BlogDetailPage from './Containers/BlogDetailPage'; 
import GalleryDetailPage from './Containers/GalleryDetailPage';
import NewsPage from './Containers/NewsPage';
import MenuDetail from './Containers/MenuDetail';
import NotFound from './Containers/NotFound';


function App() {
  return (
    <div className="App">
  <Router>
  <Routes>
    
  <Route exact path='/' activeClassName="active" element={ <Home /> }/>
  <Route exact path='/contact' activeClassName="active" element={ <Contact /> }/>
  <Route exact path='/menu/:slug' activeClassName="active" element={ <MenuDetail /> }/>
  <Route exact path='/menu' activeClassName="active" element={ <Menu /> }/>
  <Route exact path='/menu/:slug' activeClassName="active" element={ <Menu /> }/>

  <Route exact path='/career' activeClassName="active" element={ <Career/> }/>
  
  <Route exact path='/craft-of-india' activeClassName="active" element={ <Kalagram/> }/>
  
  <Route exact path='/gallery' activeClassName="active" element={ <Gallery /> }/>
  <Route exact path='/blogs' activeClassName="active" element={ <Blogs /> }/>
  <Route exact path='/blogs/category/:slug' activeClassName="active" element={ <Blogs /> }/>
  <Route exact path='/blogs/:slug' activeClassName="active" element={ <BlogDetailPage /> }/>
  <Route exact path='/gallery/:slug' activeClassName="active" element={ <GalleryDetailPage /> }/>
  <Route exact path='/news' activeClassName="active" element={ <News /> }/>
  <Route exact path='/newsdetails' activeClassName="active" element={ <NewsPage /> }/>
  <Route path="/*" element={<Navigate to="/404" replace />} />
  <Route path="/404" element={<NotFound />} />
 

  <Route exact path='/:slug' activeClassName="active" element={<Pages/>} />

 
  
  
  </Routes>
  </Router>
    </div>
  );
}

export default App;
