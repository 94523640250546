import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ApiService } from "../../Components/Services/apiservices";
import { Triangle } from 'react-loader-spinner'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
let apiServices = new ApiService();
function Kalagram() {  
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({})
  const [productCategory, setProductCategory] = useState([])
  const [productData, setProductData] = useState([])
  const [productBasrUrl, setProductBaseUrl] = useState("")
  const [defaultImage, setDefaultImage] = useState("")
  const [categorySlug, setCategorySlug] = useState("")
  const [headerImage, setHeaderImage] = useState("")
  const [sppinerLoading, setSppinerLoading] = useState(false)
  const [pageContent, setPageContent] = useState('');

  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": "craft-of-india"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setPageContent(res.data.pageData.page_content)

            setHeaderImage(res.data.page_header_url)
          }
        }
      })
      apiServices.productcategoryGetRequest().then(res => {
        if (res.data.status === "success") {
          setProductCategory(res.data.category)
          setProductData(res.data.productData)
          setProductBaseUrl(res.data.category_url)
          setDefaultImage(res.data.default_image_baseurl)
          setCategorySlug(res.data.category[0].category_slug)
        }
      })
    }
    didMountRef.current = false;
  });
  const categoryproduct = (cat_slug,index) => {
    setSppinerLoading(true)
    // setCategorySlug(cat_slug)
    const dataString = {
      "category_slug": cat_slug
    }
    apiServices.categorywiseproductPostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        document.getElementById("tab-"+index).classList.add("active")
        setProductData(res.data.productData)
        setProductBaseUrl(res.data.product_url)
        setDefaultImage(res.data.default_image_baseurl)
        setSppinerLoading(false)
      }
    })
  }
  return (
    <>
      <Helmet>
      <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokhi Dhani" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokhi Dhani" />
        {pageData.page_header_image != null ? <meta property="og:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="og:image" content= {'/assets/img/logo.png'}/>}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokhi Dhani" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="twitter:image" content= {'/assets/img/logo.png'}/>}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />
      <div id="cappa-main">
        <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center caption mt-90">
              {pageData.page_sub_header !=null ?<h5>{pageData.page_sub_header}</h5>:""}
              {pageData.page_header != null ? <h1>{pageData.page_header}</h1>:""}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {pageContent != null ?<div dangerouslySetInnerHTML={{ __html: pageContent}}></div>:""}

      <Footer />
    </>
  )
}

export default Kalagram