import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Facilities from './Facilities';
import Pricing from './Pricing';
import PromoVideo from './PromoVideo';
import Menu from './Menu';
import Services from './Services';
import Testimonials from './Testimonials';
import Blogs from './Blogs';
import Resevation from './Reservation';
import Clients from './Clients';
import { ApiService } from "../../Components/Services/apiservices";
import Modal from 'react-bootstrap/Modal';
import constant from '../../Components/Services/constant';
import HomeInstagram from './Home_Instagram';
let apiServices = new ApiService();
function Home() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    function handleShow() { setShow(true); }
    const didMountRef = useRef(true)
    const [pageData, setPageData] = useState({})
    const [headerImage, setHeaderImage] = useState("")
    const [settingData, setSettingData] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')

    useEffect(() => {
        if (didMountRef.current) {
            // setTimeout(() => {
            //  handleShow()
            // }, 30000);
            const dataString = {
                "page_url": "home"
            }
            apiServices.pagePostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    if (res.data.pageData != null) {
                        setPageData(res.data.pageData)
                        setHeaderImage(res.data.page_header_url)

                    }
                }
            })


            apiServices.settingsdataGetRequest().then(res => {
                if (res.data.status == "success") {
                    setImageUrl(res.data.settings_url)
                    setSettingData(res.data.sitesettings)
                    setDefaultImage(res.data.default_image_baseurl)
                }
            })

        }
        didMountRef.current = false;
    })

    return (
        <>
            <Helmet>
                <title>{pageData.page_meta_title}</title>
                <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : ""} />
                <meta name="keywords" content={pageData.page_meta_keyword} />
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="index,follow" />
                <meta name="author" content="Chokhi Dhani" />
                <meta property="og:title" content={pageData.page_meta_title} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Chokhi Dhani" />
                {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
                    : <meta property="og:image" content={constant.FRONT_URL +'assets/img/logo.png'} />}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:description" content={pageData.page_meta_desc} />
                <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@Chokhi Dhani" />
                <meta name="twitter:title" content={pageData.page_meta_title} />
                <meta name="twitter:description" content={pageData.page_meta_desc} />
                {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
                    : <meta property="twitter:image" content={constant.FRONT_URL +'assets/img/logo.png'} />}
                <meta name="twitter:url" content={window.location.href} />
                <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
            </Helmet>
            <Header />

            <section className="about section-padding" data-scroll-index="1">

                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-30" data-animate-effect="fadeInUp">
                            <span>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                                <i className="star-rating"></i>
                            </span>
                            <div className="section-subtitle">Welcome to Chokhi Dhani</div>
                            <div className="section-title">Chokhi Dhani- Best Indian Restaurant</div>
                            <h4>With a proud history of 30 years, Chokhi Dhani has promoted and celebrated India's local culture and hospitality.</h4>
                            <p>With a legacy spanning over three decades, Chokhi Dhani has been steadfast in preserving and promoting India's vibrant local culture and warm hospitality. Our vision has travelled beyond India's borders and brought the same essence to Dubai, where we have combined authentic Indian Cuisine, a lively ambience, and exceptional hospitality. Our menu is a tribute to the legendary royal cuisine of Rajasthan and features a wide array of dishes from across India, showcasing the diversity and richness of Indian cuisine. Join us on a culinary journey to the heart of India, right here in Dubai.</p>

                            <div className="d-flex align-items-center about-button">
                                <div className="butn-dark me-3">
                                    <a href="https://widget.servmeco.com/?oid=718" target='_blank'><span>Reservations</span></a>

                                    {/* <a href="https://widget.servmeco.com/?oid=718&source=venue_website"><span>Book Your Table</span></a> */}
                                </div>
                                <div className="reservations">
                                    <div className="icon"><span className="flaticon-call"></span></div>
                                    <div className="text">
                                        <a href={"tel:" + settingData.admin_support_mobile} className="footer-contact-phone">{settingData.admin_support_mobile}</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col col-md-3" data-animate-effect="fadeInUp">
                            <img src="/assets/img/food/home2.png" alt="" className="mt-90 mb-30" />
                        </div>
                        <div className="col col-md-3" data-animate-effect="fadeInUp">
                            <img src="/assets/img/food/home1.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body className="login-modal-content"  >
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                </Modal.Body>
            </Modal>
            <Menu />
            {/* <Pricing /> */}
            {/* <PromoVideo /> */}
            <Services />
            {/* <Testimonials /> */}
            {/* <Facilities /> */}
            <Blogs />
            {/* <HomeInstagram/> */}
            {/* <Resevation /> */}
            {/* <Clients /> */}
            <Footer />

        </>
    )
}

export default Home