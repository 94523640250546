import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Career() {
  const didMountRef = useRef(true)
  const { slug } = useParams();
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  const [pageContent, setPageContent] = useState('');
  const [saveApplyData, setSaveApplyData] = useState({ designation: '', first_name: '', last_name: '', email: '', phone_number: '' })
  const [jobData , setJobData] = useState([])
  const [jobId , setJobId] = useState("")
  const [designation , setDesignation] = useState("")
  const [successMessage , setSuccessmessage] = useState("")
  const [errorMessage , setErrorMessage] = useState("")

  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": "career"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setHeaderImage(res.data.page_header_url)
            setPageContent(res.data.pageData.page_content)

          }
        }
      })
      apiServices.alljobsGetRequest().then(res => {
        if(res.data.status=="success"){
          setJobData(res.data.jobData)
        }
      })
    }
    didMountRef.current = false;
  });
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handlechangeData = (e) => {
    const value = e.target.value
    const key = e.target.name
    setSaveApplyData({ ...saveApplyData, [key]: value })
  }
  const submitapplydata = () => {
    if (designation == "") {
      document.getElementById("designation").style.border = "1px solid red"
      return false
    }
    if (saveApplyData.first_name == "") {
      document.getElementById("first_name").style.border = "1px solid red"
      return false
    }
    if (saveApplyData.last_name == "") {
      document.getElementById("last_name").style.border = "1px solid red"
      return false
    }
    if (saveApplyData.email == "") {
      document.getElementById("email").style.border = "1px solid red"
      return false
    }
    if (!isValidEmail(saveApplyData.email)) {
      document.getElementById("email").style.border = "1px solid red"
      setErrorMessage('Email Address is invalid');
      return false
    }    
    if (saveApplyData.phone_number == "") {
      document.getElementById("phone_number").style.border = "1px solid red"
      return false
    }

    const dataString={
      "job_id":jobId,
      "designation": designation,
      "first_name": saveApplyData.first_name,
      "last_name" : saveApplyData.last_name,
      "email" : saveApplyData.email , 
      "phone_number": saveApplyData.phone_number
    }
    apiServices.careerprocessPostRequest(dataString).then(res => {
      if(res.data.status=="success"){
        setSuccessmessage(res.data.message)
         setErrorMessage("")
        setTimeout(() => {
          document.getElementById("btn_timeout").click()
          window.location.reload();
        }, 1000); 
      }
      else{
        setErrorMessage(res.data.message)
       
    }
    })
  }

  const applyhere=(jobid , designation)=>{
    setJobId(jobid)
    setDesignation(designation)
  }
  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokhi Dhani" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokhi Dhani" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
          : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokhi Dhani" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
          : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />
      <div id="cappa-main">
        <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center caption mt-90">
                {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
              </div>
            </div>
          </div>
        </div>
        <section className='about section-padding'>
          <div className='container'>
            <div className='row'>
              {jobData.map((value)=>(
              <div className='col-lg-6'>
                <div className='carerbox'>
                  <ul>
                    <li><span>Designation:</span> {value.job_designation_name}</li>
                    <li><span>Experience:</span> {value.job_exp} yr</li>
                    <li><span>location:</span> {value.job_location}</li>
                    <li><span>Salary Range:</span> {value.job_salary}</li>
                    <li><span>Specialization:</span>{value.job_spec}  </li>
                    <li dangerouslySetInnerHTML={{__html:value.job_content}} style={{marginTop: "10px"}}></li>
                  </ul>
                  <div className="butn-dark mt-4"> <a data-bs-target="#careerModal" data-bs-toggle="modal" href="#careerModal" onClick={() => applyhere(value.job_id , value.job_designation_name)}><span>Apply Here</span></a> </div>
                </div>
              </div>))}
            </div>
          </div>
        </section>
      </div>
      {/* {pageContent != null ?<div dangerouslySetInnerHTML={{ __html: pageContent}}></div>:""}   */}
      <div className="modal fade equote" id="careerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content equote-content">
            <button type="button" className="btn-close" id="btn_timeout" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3>Apply Now</h3>
           {successMessage !== '' ? <div className="alert alert-success" role="alert">{successMessage}</div> : null}
                   {errorMessage !==''?<div className="alert alert-success" role="alert" >{errorMessage}</div>:null}
            <div className='row'>
              <div className='col-lg-12'>
                <div className='form-group mb-2'>
                  <label>Designation</label>
                  <input type='text' name="designation" id="designation" value={designation} onChange={handlechangeData} disabled></input>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group mb-2'>
                  <label>First Name</label>
                  <input type='text' name="first_name" id="first_name" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group mb-2'>
                  <label>Last Name</label>
                  <input type='text' name="last_name" id="last_name" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group mb-2'>
                  <label>Email Id</label>
                  <input type='email' name="email" id="email" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group mb-2'>
                  <label>Phone Number</label>
                  <input type='number' name="phone_number" id="phone_number" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className="butn-dark mt-3"><button type="button" className='butn-dark-full' onClick={submitapplydata}><span>Submit</span></button></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default Career


















































// import React, { useEffect,useRef, useState } from 'react'
// import { Helmet } from "react-helmet";
// import Footer from "../../Components/Footer";
// import {useParams} from "react-router-dom";
// import PagesHeader from '../../Components/PagesHeader';
// import { ApiService } from "../../Components/Services/apiservices";
// let apiServices = new ApiService();
// function Career(){
//     const didMountRef = useRef(true)
//     const { slug } = useParams();
//     const [pageData , setPageData] = useState({})
//     const [headerImage , setHeaderImage] = useState("")
//     const [pageContent, setPageContent] = useState('');

//     useEffect(() => {
//         if (didMountRef.current) { 
//        const dataString = {
//       "page_url" : "career"
//        }
//        apiServices.pagePostRequest(dataString).then(res => {
//         if(res.data.status === "success"){
//             if(res.data.pageData != null){
//           setPageData(res.data.pageData)
//           setHeaderImage(res.data.page_header_url)
//           setPageContent(res.data.pageData.page_content)

//             }
//        }
//     })
//     }

//   didMountRef.current = false;
//   }); 
//   return (
//     <>
//     <Helmet>
//     <title>{pageData.page_meta_title}</title>
//         <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
//         <meta name="keywords" content={pageData.page_meta_keyword} />
//         <link rel="canonical" href={window.location.href} />
//         <meta name="robots" content="index,follow" />
//         <meta name="author" content="Chokhi Dhani" />
//         <meta property="og:title" content={pageData.page_meta_title} />
//         <meta property="og:type" content="website" />
//         <meta property="og:site_name" content="Chokhi Dhani" />
//         {pageData.page_header_image != null ? <meta property="og:image" content= {headerImage + pageData.page_header_image}/>
//                 :<meta property="og:image" content= {'/assets/img/logo.png'}/>}
//         <meta property="og:url" content={window.location.href} />
//         <meta property="og:description" content={pageData.page_meta_desc} />
//         <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:site" content="@Chokhi Dhani" />
//         <meta name="twitter:title" content={pageData.page_meta_title} />
//         <meta name="twitter:description" content={pageData.page_meta_desc} />
//         {pageData.page_header_image != null ? <meta property="twitter:image" content= {headerImage + pageData.page_header_image}/>
//                 :<meta property="twitter:image" content= {'/assets/img/logo.png'}/>}
//         <meta name="twitter:url" content={window.location.href} />
//         <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
//       </Helmet>



//         <PagesHeader/>

//         <div id="cappa-main">
//         <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3"  style={{backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
//         <div className="container">
//             <div className="row">
//                 <div className="col-md-12 text-left caption mt-90">
                    
//               {pageData.page_header != null ?<h1>{pageData.page_header}</h1>:""}
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// {pageContent != null ?<div dangerouslySetInnerHTML={{ __html: pageContent}}></div>:""}  
//         <Footer/>
//         </>
//     )
// }
// export default Career