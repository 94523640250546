import React, { useState, useRef, useEffect } from 'react'
import { ApiService } from "../../Components/Services/apiservices";

let apiServices = new ApiService();
function Reservation() {
	const [calenderDate, setCalenderDate] = useState("")
	const [showLogin, setShowLogin] = useState(false);
	const [settingData, setSettingData] = useState('')
	const [imageUrl, setImageUrl] = useState('')
	const [defaultImage, setDefaultImage] = useState('')
	const didMountRef = useRef(true)
	useEffect(() => {
		if (didMountRef.current) {
			var today = new Date()
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();
			var calender = dd + "/" + mm + "/" + yyyy;
			setCalenderDate(calender)
			apiServices.settingsdataGetRequest().then(res => {
				if (res.data.status == "success") {
					setImageUrl(res.data.settings_url)
					setSettingData(res.data.sitesettings)
					setDefaultImage(res.data.default_image_baseurl)
				}
			})

		}
		didMountRef.current = false;
	}, []);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = "//widget.tagembed.com/embed.min.js";
		script.type = "text/javascript";
		script.async = true;
		document.body.appendChild(script);
		setTimeout(() => {
		  const element = document.querySelector('.tb_theme_container > div:first-child');
		  if (element) {
			element.style.display = 'none';
		  }
		}, 1500);
		return () => {
		  document.body.removeChild(script);
		};
	  }, []);
	return (
		<>
			<section className="testimonials">
				<div className="background bg-img bg-fixed section-padding pb-0" data-background="/assets/img/slider/Contact.jpg" data-overlay-dark="2">
					<div className="container">
						<div className="row">
							<div className="col-md-5 mb-30 mt-30">

								<p><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i></p>
								<h5> Explore the Tastes of India.</h5>
								<a href={"tel:" + settingData.admin_support_mobile}>
									<div className="reservations mb-30">

										<div className="icon color-1"><span className="flaticon-call"></span></div>
										<div className="text">
											<p className="color-1">Reservations</p> <a className="color-1" href={"tel:" + settingData.admin_support_mobile}>{settingData.admin_support_mobile}</a>
										</div>
									</div>
								</a>

							</div>
		<div className="col-md-5 offset-md-2">
			{/* <div className="booking-box">
		<div className="resiframe">
			<iframe src="https://widget.servmeco.com/?oid=718" frameborder="0" ></iframe>

		</div>

<div className="booking-inner clearfix" id='bookingopentable'>

</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default Reservation