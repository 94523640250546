import React, { useEffect, useRef, useState, useTransition } from 'react'
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import PagesHeader from '../../Components/PagesHeader';
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Triangle } from 'react-loader-spinner'
import { ApiService } from "../../Components/Services/apiservices";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import Testimonials from '../Home/Testimonials';
let apiServices = new ApiService()

function MenuDetail() {
    const ref = useRef(null)
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({})
    const [headerImage, setHeaderImage] = useState("")
    const [menuCategory, setMenuCategory] = useState([])
    const [categorySlug, setCategorySlug] = useState("")
    const [menuData , setmenuData] = useState([])
    const [sppinerLoading, setSppinerLoading] = useState(false)
    const [pageContent, setPageContent] = useState('')
    const [pageChange , setPageChange] = useState(0)
    const [categoryBannerImage , setCategoryBannerImage] = useState("")
    const [menuImageUrl , setMenuImageUrl] = useState("") 
    const [pdfCategoryName , setPdfCategoryName] =  useState("")
    const [categoryDesc , setCategoryDesc] =  useState("")

    const {slug} = useParams()
    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
                "page_url": "menu"
            }
            apiServices.pagePostRequest(dataString).then(res => {
                if (res.data.status === "success") {
                    if (res.data.pageData != null) {
                        setPageData(res.data.pageData)
                        setPageContent(res.data.pageData.page_content)
                        setHeaderImage(res.data.page_header_url)
                    }
                }
            })
            setSppinerLoading(true)            
                    if(slug != null && slug != undefined){ 
                    const dataString2  = {
                        "category_slug":slug
                    }
                    apiServices.categorywisemenuPostRequest(dataString2).then(res => {
                        if(res.data.status == "success"){
                            setmenuData(res.data.menudata)
                            setPageChange(pageChange+1)
                            setCategoryBannerImage(res.data.category.category_banner_image)
                            setMenuImageUrl(res.data.menu_url)
                            setPdfCategoryName(res.data.category.category_name)
                            setCategoryDesc(res.data.category.category_desc)

                        }})
                    }
                 
                        apiServices.menucategoryGetRequest().then(res => {
                            if (res.data.status == "success") {
                                setCategorySlug(res.data.category[0].category_slug)
                                setMenuCategory(res.data.category)
                    }
                })        
                    setSppinerLoading(false)
             
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
        }
        didMountRef.current = false;
    });
  return (
    <>
    <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokhi Dhani" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokhi Dhani" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
            : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokhi Dhani" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
            : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
    </Helmet>
    <PagesHeader />
    <div id="cappa-main">
    <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center caption mt-90">
                {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
                {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
            </div>
        </div>
    </div>
</div>

<section id="menu" className="restaurant-menu menu section-padding bg-blck pt-0 mt-5" ref={ref}>
                    <div className="container">
                    <div className='row'>
                            <div className='col-lg-12'>
                               
                                <div className='mlist'>
                                    <ul>
                                        {menuCategory.map((value) => (
                                            <li style={{color:"#aa8453" , cursor:"pointer"}} className='butn-dark' >
                                                <a href={`/menu/` + value.category_slug} className='w-100'><span>{value.category_name}</span></a>
                                                
                                                </li>))}

                                          

                                    </ul>
                                 
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="section-title" style={{ marginTop: "50px" }}><span>{pdfCategoryName}</span></div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                 <div className="restaurant-menu-content col-md-12 mt-5" >
                                        <div className='row text-center mb-5'>
                                            <div className='col-md-12'>
                                                <div className="butn-dark" > <a href={menuImageUrl + categoryBannerImage} target="new"><span>{pdfCategoryName}</span></a> </div>
                                           <p className='mt-3'>{categoryDesc}</p>
                                            </div>
                                        </div>

                                        <div className="cont active" >
                                            {
                                                menuData.map((value) => (
                                                <>
                                                    <div className='row text-center' >
                                                        <div className='col-md-12'>
                                                            <div className="section-title" style={{fontSize: "30px" }}><span>{value.category_name}</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5" >
                                                        {value.menudata.map((subvalue) => (
                                                            <div className="col-md-6" >
                                                                <div className="menu-info">
                                                                    <h5>{subvalue.menu_name}{subvalue.menu_starting_price != null ? <span className="price">{"AED" + " " + subvalue.menu_starting_price}</span> : ""}</h5>

                                                                    {subvalue.menu_description != null ? <p>{subvalue.menu_description}</p> : ""}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                                ))
                                            }
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
<Footer />
        </>
  )
}

export default MenuDetail