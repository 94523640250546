import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer"
import PagesHeader from "../../Components/PagesHeader"
import { useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService()

function News() {

  const didMountRef = useRef(true);
  const { slug } = useParams();
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  const [partnerData, setPartnerData] = useState([])
  const [partnerImageUrl, setPartnerImageUrl] = useState("")
  const [defaultImage, setDefaultImage] = useState("")


  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": "news"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {

          if (res.data.pageData != null) {

            setPageData(res.data.pageData)
            setHeaderImage(res.data.page_header_url)
          }

        }
      })
      apiServices.newspartnerUrlGetRequest(dataString).then(res => {
        if (res.data.status == "success") {
          setPartnerData(res.data.partnerData)
          setPartnerImageUrl(res.data.partner_url)
          setDefaultImage(res.data.default_image_baseurl)
        }
      })
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokhi Dhani" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokhi Dhani" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
          : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokhi Dhani" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
          : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />


      <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center caption mt-90">
              {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}

              {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
            </div>
          </div>
        </div>
      </div>
      <section className="about section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            {partnerData.map((value) => (
              <div className="col-lg-3">
                <div className="innews">
                  <div className="innews-img">
                    <a href={value.client_link} target="_new">
                      <img src={value.client_image != null ? partnerImageUrl + value.client_image : defaultImage}></img>
                    </a>
                  </div>
                  <div className="innews-content">
                    <a href={value.client_link} className="innews-title" target="new">{value.client_title}</a>
                  </div>
                </div>
              </div>))}
          </div>
        </div>
      </section>
      <Footer />
    </>


  )

}

export default News;