import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import { useParams, useNavigate } from "react-router-dom";

import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";



let apiServices = new ApiService();
function Pages() {
  const didMountRef = useRef(true);
  const { slug } = useParams();
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  const [pageContent, setPageContent] = useState('');
  const [saveData, setSaveData] = useState({ enquiry_name: "", enquiry_email: "", enquiry_mobile: "", enquiry_purpose: "", enquiry_event_for: "" })
  const [successMessage, setSuccessmessage] = useState('')
  const [pageChange, setPageChange] = useState(1)
  const [pageSlug, setPageSlug] = useState("")

  useEffect(() => {
    if (didMountRef.current) {
      setPageSlug(slug)
      const dataString = {
        "page_url": slug
      }

      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setPageContent(res.data.pageData.page_content)
            setHeaderImage(res.data.page_header_url)
          }
          else {
            navigate("/404");
          }
        }
      })

    }
    didMountRef.current = false;
  });

  const handlechangeData = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setSaveData({ ...saveData, [key]: value })
  }
  const submitmessage = (e) => {
    // console.log(saveData)

    e.preventDefault()
    if (saveData.enquiry_name == "") {
      document.getElementById("enquiry_name").style.border = "1px solid red"
      return false
    }
    else if (saveData.enquiry_email == "") {
      document.getElementById("enquiry_email").style.border = "1px solid red"
      return false
    }
    else if (saveData.enquiry_mobile == "") {
      document.getElementById("enquiry_mobile").style.border = "1px solid red"
      return false
    }
    else if (saveData.enquiry_mobile == "") {
      document.getElementById("enquiry_event_for").style.border = "1px solid red"
      return false
    }
    else if (saveData.enquiry_purpose == "") {
      document.getElementById("enquiry_purpose").style.border = "1px solid red"
      return false
    }
    const dataString = {
      "enquiry_page": pageData.page_id,

      "enquiry_name": saveData.enquiry_name,
      "enquiry_email": saveData.enquiry_email,
      "enquiry_mobile": saveData.enquiry_mobile,
      "enquiry_purpose": saveData.enquiry_purpose,
      "enquiry_event_for": saveData.enquiry_event_for
    }
    apiServices.enquiryprocessPostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        setSuccessmessage(res.data.message)

        setTimeout(() => {
          document.getElementById("btn_timeout").click()
          window.location.href = "/thank-you";
        }, 2000);
      }
    })
  }
  const navigate = useNavigate();

  return (
    <>

      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Chokhi Dhani" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Chokhi Dhani" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
          : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Chokhi Dhani" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
          : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />
      <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${pageData.page_header_image != null ? headerImage + pageData.page_header_image : ""})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center caption mt-90">
              {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
              {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
            </div>
          </div>
        </div>
      </div>

      {pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}
      <div className="modal fade equote" id="equoteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content equote-content">
            <button type="button" className="btn-close" id="btn_timeout" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3>Get a Quote</h3>
            {successMessage !== '' ? <div className="alert alert-success" role="alert">{successMessage}</div> : null}
            <div className='row'>
              <div className='col-lg-12'>
                <div className='form-group mb-2'>
                  <label>Full Name</label>
                  <input type='text' name="enquiry_name" id="enquiry_name" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group mb-2'>
                  <label>Email Id</label>
                  <input type='email' name="enquiry_email" id="enquiry_email" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='form-group mb-2'>
                  <label>Phone Number</label>
                  <input type='number' name="enquiry_mobile" id="enquiry_mobile" onChange={handlechangeData}></input>
                </div>
              </div>
              {pageSlug != "whats-on" ?
                <div className='form-group mb-2'>
                  <label>Event Type</label>

                  <select name='enquiry_event_for' id='enquiry_event_for' onChange={handlechangeData}>
                    <option>Event Type</option>
                    <option value="Corporate Events">Corporate Events</option>
                    <option value="Special Occasion">Special Occasion</option>
                    <option value="Private Dining">Private Dining</option>
                    <option value="Weddings">Weddings</option>

                  </select>
                </div> : ""}


              <div className='col-lg-12'>
                <div className='form-group'>
                  <label>Purpose</label>
                  <textarea name="enquiry_purpose" id="enquiry_purpose" onChange={handlechangeData}></textarea>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className="butn-dark mt-3"><button type="button" className='butn-dark-full' onClick={submitmessage}><span>Get a Quote</span></button></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />


    </>
  )
}

export default Pages;