import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from '../../Components/Services/apiservices'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';





const apiServices = new ApiService()
function Clients() {

  const didMountRef = useRef(true)
  const[partnerData , setPartnerData] = useState([])
  const [partnerImageUrl , setPartnerImageUrl] = useState("")
  const[defaultImage , setDefaultImage] = useState("")

  useEffect(()=>{
    if(didMountRef.current){
      apiServices.newspartnerUrlGetRequest().then(res=>{
        if(res.data.status === "success"){
          setPartnerData(res.data.partnerData)        
          setPartnerImageUrl(res.data.partner_url)
          setDefaultImage(res.data.default_image_baseurl)
        }

      })
    }
didMountRef.current = false
  })

  return (
    <>
      <section className="clients">
         <div className="container">
          <div className="row">
          <div className="col-md-7">
          <Swiper
                    modules={[Pagination, Autoplay]}
                   autoplay={{delay: 1500}}
                
                    spaceBetween={12}
                    breakpoints={{
                      // when window width is >= 640px
                      340: {
                        width: 340,
                        slidesPerView: 2,
                      },
                      640: {
                        width: 640,
                        slidesPerView: 2,
                      },
                      // when window width is >= 768px
                      768: {
                        width: 768,
                        slidesPerView: 3,
                      },
                      1000: {
                        width: 1000,
                        slidesPerView: 4,
                      },
                      1200: {
                        width: 1200,
                        slidesPerView: 7,
                      },
                    }} >
                  {partnerData.map((value)=>(
                      <SwiperSlide>
          
                         <div className="clients-logo" >
                  <a href={value.client_link} target="_new"><img src={value.client_image != null ? partnerImageUrl + value.client_image :defaultImage} alt={value.client_title} /></a>
                </div> 

                      </SwiperSlide>))}

                
                  </Swiper>
                  </div>
    
          </div>
        </div>
      </section>

    </>
  )
}

export default Clients