
import React from 'react'
function Services() {
  return (
    <>

      <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 p-0 " data-animate-effect="fadeInLeft">
              <div className="img left">
                <a href="/kalagram"><img src="/assets/img/food/Desi_Store.jpg" alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 p-0 bg-blck valign " data-animate-effect="fadeInRight">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Discover</h6>
                  </div>
                  <h4>Desi Store</h4>
                  <p>The ‘Desi Store’ is a rustic-contemporary shopping hub at Chokhi Dhani Dubai, where you will find everything from Rajasthani textiles, jewellery, home decor accents and paintings.</p>
                  <div className="butn-dark"> <a href="/kalagram"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 bg-blck p-0 order2 valign " data-animate-effect="fadeInLeft">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Modern</h6>
                  </div>
                  <h4>Crafts Of India</h4>
                  <p>Explore a wide range of Indian handicrafts, jewellery, and home decor from Kalagram, an exclusive brand available only at Chokhi Dhani. </p>
                  <div className="butn-dark"> <a href="/craft-of-india"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 order1 " data-animate-effect="fadeInRight">
              <div className="img">
                <a href="/craft-of-india"><img src="/assets/img/food/Kalagram.jpeg" alt="" /></a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 p-0 " data-animate-effect="fadeInLeft">
              <div className="img left">
                <a href="/indian-street-food-deck"><img src="/assets/img/food/Deck.jpg" alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 p-0 bg-blck valign " data-animate-effect="fadeInRight">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences </h6>
                  </div>
                  <h4>Deck</h4>
                  <p>Tuck into some authentic Indian street food treats at Chokhi Dhani’s Deck. The seating is endowed with vibrant furniture, dhol, pagris, puppets, charpais and chokis that reflect the Rajasthani tradition’s grandeur!</p>
                  <div className="butn-dark"> <a href="/indian-street-food-deck"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 bg-blck p-0 order2 valign " data-animate-effect="fadeInLeft">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Modern</h6>
                  </div>
                  <h4>Entertainment</h4>
                  <p>The warmth of a personalized gift is unmatched and especially when it is a Portrait drawn by experienced portrait artists, it is something to cherish forever.</p>
                  <div className="butn-dark"> <a href="/entertainment"><span>Learn More</span></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 order1 " data-animate-effect="fadeInRight">
              <div className="img">
                <a href="/entertainment"><img src="/assets/img/food/Entertainment.jpg" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Services