import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router-dom";

function PagesHeader() {

    const location = useLocation();

    return (
        <>
            <div className="progress-wrap cursor-pointer">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
            {/* <div className="sidebutton">
                <a href="https://widget.servmeco.com/?oid=718" className="reserbutton" target='_blank'>Reservations</a>
                <a href="/special-offers" className="whatsnewbutton">Special Offers</a>
            </div> */}
            <nav className="navbar navbar-expand-md">
                <div className="container-fluid">
                    <div className="logo-wrapper mlogo">
                        <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="ti-menu"></i></span> </button>
                    <div className="collapse navbar-collapse" id="navbar">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item"><a href="/" className={location.pathname === "/" ? "nav-link active" : "nav-link"}>Home</a></li>
                            {/* <li className="nav-item"><a href="/kalagram" className={location.pathname.includes("/kalagram") ? "nav-link active" : "nav-link"}>Desi Store</a></li> */}
                            <li className="nav-item"><a href="/entertainment" className={location.pathname.includes("/entertainment") ? "nav-link active" : "nav-link"}>Entertainment</a></li>
                            
                            <li className="nav-item"><a href="/special-offers" className={location.pathname.includes("/special-offers") ? "nav-link active" : "nav-link"}>Special Offers</a></li>
                            <li className="nav-item"><a href="/craft-of-india" className={location.pathname.includes("/craft-of-india") ? "nav-link active" : "nav-link"}>Craft Of India</a></li>

                            <div className="logo-wrapper mclogo">
                                <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                            </div>
                            <li className="nav-item"><a href="/menu" className={location.pathname.includes("/menu") ? "nav-link active" : "nav-link"}>Menu</a></li>

                            <li className="nav-item"><a href="/indian-street-food-deck" className={location.pathname.includes("/indian-street-food-deck") ? "nav-link active" : "nav-link"}>Deck</a></li>
                           
                            {/* <li className="nav-item"><a href="/about" className={location.pathname.includes("/about") ? "nav-link active" : "nav-link"}>About Us</a></li> */}

                            {/* <li className="nav-item"><a href="/news" className={location.pathname.includes("/news") ?"nav-link active":"nav-link"}>In News</a></li>  */}

                            <li className="nav-item"><a href="/events" className={location.pathname.includes("/events") ? "nav-link active" : "nav-link"}>Events</a></li>

                            {/* <li className="nav-item"><a href="/blogs" className={location.pathname.includes("/blogs") ? "nav-link active" : "nav-link"}>Blog</a></li> */}
                            <li className="nav-item "><a href="/reservations" className={location.pathname.includes("/reservations") ? "nav-link active" : "nav-link"}>Reservations</a></li>
                            
                            <li className="nav-item"><a href="/contact" className={location.pathname.includes("/contact") ? "nav-link active" : "nav-link"}>Contact</a></li>
                         
                            {/* <li className="nav-item mres"><a href="/reservations" className={location.pathname.includes("/reservations") ? "nav-link active" : "nav-link"}>Reservations</a></li> */}
                            <div className="butn-dark"> <a className='reservebutton' href="https://widget.servmeco.com/?oid=718" target='_blank'><span>Reserve a Table</span></a> </div>

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default PagesHeader