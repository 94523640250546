import React, { useEffect, useState, useRef } from 'react';
import { ApiService } from '../Services/apiservices';
import Modal from 'react-bootstrap/Modal';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

let apiServices = new ApiService();
function Footer() {
  const [settingData, setSettingData] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [whatsappUrl, setWhatsappUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const didMountRef = useRef(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Email, setEmail] = useState('')
  const [successMessage, setSuccessmessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const location = useLocation();
  useEffect(() => {
    if (didMountRef.current) {
      apiServices.settingsdataGetRequest().then(res => {
        if (res.data.status == "success") {
          setImageUrl(res.data.settings_url)
          setSettingData(res.data.sitesettings)
          setDefaultImage(res.data.default_image_baseurl)
        }
      })
    }
    didMountRef.current = false;
  }, [])

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const submitmessage = (e) => {
    e.preventDefault()
    //console.log("Email",Email);
    if (Email === "") {
      document.getElementById("newsletter_email").style.border = "1px solid red"
      setEmail("")
      return false

    }
    if (!isValidEmail(Email)) {
      document.getElementById("newsletter_email").style.border = "1px solid red"
      setErrorMessage('Email is invalid');
      return false
    }
    setErrorMessage('');
    document.getElementById("newsletter_email").style.border = "1px solid #aa8453"
    const dataString = {
      "newsletter_email": Email,
    }

    apiServices.newsletterPostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        setSuccessmessage(res.data.message)
        setEmail("")
      }
      else {
        setErrorMessage(res.data.message)
        setEmail("")
      }
      setTimeout(() => {
        document.getElementById("btn_timeout").click()
        window.location.reload();
      }, 2000);
    });
  }

  function getquotemodal() {
    setShow(true);
  }
  return (
    <>
      <BrowserView>

        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-3">

                  <div className="footer-column footer-about">
                    <div className="footer-logo" style={{ marginBottom: '10px' }}>
                      <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                    </div>
                    <h3 className="footer-title">Chokhi Dhani</h3>
                    <p className="footer-about-text"

                      dangerouslySetInnerHTML={{ __html: settingData.admin_share_message }}>
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className="col-md-6 col-6">
                      <div className="footer-column footer-explore clearfix">
                        <h3 className="footer-title">Useful Links</h3>
                        <ul className="footer-explore-list list-unstyled">
                          <li><a href="/kalagram">Desi Store</a></li>
                          <li><a href="/craft-of-india">Crafts Of India</a></li>
                          <li><a href="/menu">Menu</a></li>
                          <li><a href="indian-street-food-deck">Deck</a></li>
                          <li><a href="/entertainment">Entertainment</a></li>
                          <li><a href="/gallery">Gallery</a></li>
                          <li><a href="/news">In News</a></li>

                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="footer-column footer-explore clearfix">
                        <h3 className="footer-title">Quick Links</h3>
                        <ul className="footer-explore-list list-unstyled">
                          <li><a href="/indian-food">Indian Food</a></li>
                          <li><a href="/about">About Us</a></li>
                          <li><a href="/career">Career</a></li>
                          <li><a href="/blogs">Blog</a></li>

                          <li><a href="/contact">Contact</a></li>
                          <li><a href="/thank-you">Thank You</a></li>
                        </ul>
                        <div className="butn-dark"> <a href="https://widget.servmeco.com/?oid=718" target='_blank'><span>Reservations</span></a> </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='newsletterbox'>
                        <h3 className="footer-title">Newsletter</h3>
                        {successMessage !== '' ? <div className="alert alert-success" role="alert" style={{ color: "green" }}>{successMessage}</div> : null}
                        {errorMessage !== '' ? <div className="alert alert-danger" role="alert" style={{ color: "red" }}>{errorMessage}</div> : null}
                        <div className="input-group footer-subscription-form">
                          <input type="email" className="form-control" id="newsletter_email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} value={Email}></input>
                          <button className="btn01" type="button" id="btn_timeout" onClick={submitmessage}>Subscribe</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="footer-column footer-contact">
                    <h3 className="footer-title">Contact</h3>
                    <div className="footer-contact-info">
                      <a href="https://goo.gl/maps/JaparKMX1ogdPfBs5" className="footer-contact-phone" target="new"><span className="flaticon-marker"></span> {settingData.address}</a>
                    </div>
                    <div className="footer-contact-info">
                      <a href={"tel:" + settingData.admin_support_mobile} className="footer-contact-phone"><span className="flaticon-call"></span>  {settingData.admin_support_mobile}</a>
                      <a href={"mailto:" + settingData.admin_email} className="footer-contact-phone"><span className="flaticon-envelope"></span> {settingData.admin_email}</a>

                    </div>

                    <h3 className="footer-title mt-3">Follow us on</h3>
                    <div className="footer-about-social-list">

                      {
                        settingData.facebook_url != null ?
                          <a href={settingData.facebook_url} target="new"><i className="ti-facebook"></i></a>
                          : null}
                      {
                        settingData.twitter_url != null ?
                          <a href={settingData.twitter_url} target="new" ><i className="ti-twitter"></i></a>
                          : null}

                      {
                        settingData.linkedin_url != null ?
                          <a href={settingData.linkedin_url} target="new"><i className="ti-linkedin"></i></a>
                          : null}
                      {
                        settingData.instagram_url != null ?
                          <a href={settingData.instagram_url} target="new"><i className="ti-instagram" ></i></a>
                          : null}

                      {
                        settingData.youtube_url != null ?
                          <a href={settingData.youtube_url} target="new"><i className="ti-youtube"></i></a>
                          : null}


                      {
                        settingData.pinterest_url != null ?
                          <a href={settingData.pinterest_url} target="new"><i className="ti-pinterest"></i></a>
                          : null}

                      {
                        settingData.tik_tok_url != null ?
                          <a href={settingData.tik_tok_url} target="new"><img src="/assets/img/tiktok.png" alt="" />

                          </a>
                          : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-bottom-inner">
                    <p className="footer-bottom-copy-right">© Copyright 2023 by <a href="/">Chokhi Dhani. All rights reserved.</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </BrowserView>
      <MobileView>
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-3">

                  <div className="footer-column footer-about">
                    <div className="footer-logo" style={{ marginBottom: '10px' }}>
                      <a className="logo" href="/"> <img src="/assets/img/Chokhi_Dhani.png" className="logo-img" alt="" /> </a>
                    </div>
                    <h3 className="footer-title">Chokhi Dhani</h3>
                    <p className="footer-about-text"

                      dangerouslySetInnerHTML={{ __html: settingData.admin_share_message }}>
                    </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='row'>
                    <div className="col-md-6 col-6">
                      <div className="footer-column footer-explore clearfix">
                        <h3 className="footer-title">Useful Links</h3>
                        <ul className="footer-explore-list list-unstyled">
                          <li><a href="/kalagram">Desi Store</a></li>
                          <li><a href="/craft-of-india">Crafts Of India</a></li>
                          <li><a href="/menu">Menu</a></li>
                          <li><a href="indian-street-food-deck">Deck</a></li>
                          <li><a href="/entertainment">Entertainment</a></li>
                          <li><a href="/gallery">Gallery</a></li>
                          <li><a href="/news">In News</a></li>

                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="footer-column footer-explore clearfix">
                        <h3 className="footer-title">Quick Links</h3>
                        <ul className="footer-explore-list list-unstyled">
                          <li><a href="/indian-food">Indian Food</a></li>
                          <li><a href="/about">About Us</a></li>
                          <li><a href="/career">Career</a></li>
                          <li><a href="/blogs">Blog</a></li>

                          <li><a href="/contact">Contact</a></li>
                          <li><a href="/thank-you">Thank You</a></li>
                        </ul>
                        <div className="butn-dark"> <a href="https://widget.servmeco.com/?oid=718" target='_blank'><span>Reservations</span></a> </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='newsletterbox'>
                        <h3 className="footer-title">Newsletter</h3>
                        {successMessage !== '' ? <div className="alert alert-success" role="alert" style={{ color: "green" }}>{successMessage}</div> : null}
                        {errorMessage !== '' ? <div className="alert alert-danger" role="alert" style={{ color: "red" }}>{errorMessage}</div> : null}
                        <div className="input-group footer-subscription-form">
                          <input type="email" className="form-control" id="newsletter_email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} value={Email}></input>
                          <button className="btn01" type="button" id="btn_timeout" onClick={submitmessage}>Subscribe</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="footer-column footer-contact">
                    <h3 className="footer-title">Contact</h3>
                    <div className="footer-contact-info">
                      <a href="https://goo.gl/maps/JaparKMX1ogdPfBs5" className="footer-contact-phone" target="new"><span className="flaticon-marker"></span> {settingData.address}</a>
                    </div>
                    <div className="footer-contact-info">
                      <a href={"tel:" + settingData.admin_support_mobile} className="footer-contact-phone"><span className="flaticon-call"></span>  {settingData.admin_support_mobile}</a>
                      <a href={"mailto:" + settingData.admin_email} className="footer-contact-phone"><span className="flaticon-envelope"></span> {settingData.admin_email}</a>

                    </div>

                    <h3 className="footer-title mt-3">Follow us on</h3>
                    <div className="footer-about-social-list">

                      {
                        settingData.facebook_url != null ?
                          <a href={settingData.facebook_url} target="new"><i className="ti-facebook"></i></a>
                          : null}
                      {
                        settingData.twitter_url != null ?
                          <a href={settingData.twitter_url} target="new" ><i className="ti-twitter"></i></a>
                          : null}

                      {
                        settingData.linkedin_url != null ?
                          <a href={settingData.linkedin_url} target="new"><i className="ti-linkedin"></i></a>
                          : null}
                      {
                        settingData.instagram_url != null ?
                          <a href={settingData.instagram_url} target="new"><i className="ti-instagram" ></i></a>
                          : null}

                      {
                        settingData.youtube_url != null ?
                          <a href={settingData.youtube_url} target="new"><i className="ti-youtube"></i></a>
                          : null}


                      {
                        settingData.pinterest_url != null ?
                          <a href={settingData.pinterest_url} target="new"><i className="ti-pinterest"></i></a>
                          : null}

                      {
                        settingData.tik_tok_url != null ?
                          <a href={settingData.tik_tok_url} target="new"><img src="/assets/img/tiktok.png" alt="" />

                          </a>
                          : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-bottom-inner">
                    <p className="footer-bottom-copy-right">© Copyright 2023 by <a href="/">Chokhi Dhani. All rights reserved.</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div className="appBottomMenu">
        <div className="butn-dark"><a href="https://widget.servmeco.com/?oid=718" target='_blank'><span>Reservations</span></a> </div>
        <div className="butn-dark"><a href="tel:+97143232425"><span>Call Now</span></a></div>
        </div>
      </MobileView>

      {settingData.admin_whatsapp_no && (<div className="whatsapp"><a href={`https://wa.me/${settingData.admin_whatsapp_no}`} target="_blank"><img src="/assets/img/whatsapp.png" ></img></a></div>)}
    </>
  )
}
export default Footer
